<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
	      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getTableData()">
		      <el-row>
			      <el-col :md="8">
				      <el-form-item label="检验结果:">
					      <el-select v-model="searchForm.iqcr_status" filterable placeholder="请选择检验结果" clearable size="small">
						      <el-option
								      v-for="item in iqcrStatusList"
								      :key="item.value"
								      :label="item.label"
								      :value="item.value">
						      </el-option>
					      </el-select>
				      </el-form-item>
			      </el-col>
			      <el-col :md="8">
				      <el-form-item label="物料名称:">
					      <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写中文品名"/>
				      </el-form-item>
			      </el-col>
			      <el-col :md="8" v-if="this.$cookies.get('userInfo').acct_id === 1">
				      <el-form-item label="按账套查看:">
					      <el-select v-model="searchForm.acct_id" filterable placeholder="请选择按账套" clearable size="small">
						      <el-option
								      v-for="item in acctList"
								      :key="item.acct_id"
								      :label="item.acct_no"
								      :value="item.acct_id">
						      </el-option>
					      </el-select>
				      </el-form-item>
			      </el-col>
		      </el-row>
		      <el-row>
			      <el-col :md="8">
				      <el-form-item label="检验类别:">
					      <el-select v-model="searchForm.iqcr_type" filterable placeholder="请选择检验类别" clearable size="small">
						      <el-option
								      v-for="item in iqcrTypeList"
								      :key="item.value"
								      :label="item.label"
								      :value="item.value">
						      </el-option>
					      </el-select>
				      </el-form-item>
			      </el-col>
			      <el-col :md="16">
				      <el-form-item label="检验时间:">
					      <el-date-picker
							      size="small"
							      v-model="timeArray"
							      type="daterange"
							      range-separator="至"
							      start-placeholder="开始日期"
							      end-placeholder="结束日期"/>
				      </el-form-item>
				      <el-form-item>
					      <el-button class="vg_ml_16" size="small" type="primary" icon="el-icon-search" @click="getTableDataNow()">查询</el-button>
					      <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()">
						      <i class="el-icon-refresh-right"/>刷新
					      </el-button>
				      </el-form-item>
			      </el-col>
					</el-row>
	      </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
      </div>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" v-loading="loadingFlag" :data="tableData" border stripe>
	          <el-table-column type="index" label="序号" align="center"></el-table-column>
            <el-table-column label="检验类别" prop="iqcr_type" :formatter="getIqcrTypeName" align="center"/>
            <el-table-column label="物料名称" prop="mtrl_name" show-overflow-toolti align="center" min-width="160"/>
            <!-- <el-table-column label="物料类型" prop="mtrl_type" :formatter="formatLeavType" align="center"/> -->
            <el-table-column label="订单数量" prop="mtrl_num" align="center"/>
            <el-table-column label="检验数量" prop="iqcr_num" align="center"/>
            <el-table-column label="不通过数量" prop="iqcr_fnum" align="center"/>
	          <el-table-column label="检验时间" align="center">
		          <template v-slot="scope">
			          <span>{{scope.row.create_time | formatDate }}</span>
		          </template>
	          </el-table-column>
	          <el-table-column label="检验结果" prop="iqcr_status" align="center">
		          <template v-slot="scope">
			          <el-tag v-if="scope.row.iqcr_status === 1" type="success" size="mini" plain>合格</el-tag>
			          <el-tag v-if="scope.row.iqcr_status === 2" type="danger" size="mini" plain>不合格</el-tag>
		          </template>
	          </el-table-column>
	          <el-table-column label="录入人" prop="stff_name" align="center"/>
	          <el-table-column label="*所属账套" v-if="this.$cookies.get('userInfo').acct_id === 1" prop="acct_no" align="center"/>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage='totalPage' @changePageSearch="changePageSearch" ref="pubPagination"/>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {qmttAPI} from "@api/modules/qmtt"
import pubPagination from "@/components/common/pubPagination";
import helper from "@assets/js/helper.js"
import {acctAPI} from "@api/modules/acct";
import {iqcrAPI} from "@api/modules/iqcr";
export default {
name: "IQCRList",
  components: {
    pubPagination,
  },
  data() {
    return {
	    timeArray: [],
      searchForm: {
	      page_no: 1,
      },
      currentPage: 1,
      totalPage:0,
      tableData: [],
      btn:{},
      loadingFlag:true,
	    iqcrStatusList: [
		    {label: '合格', value: 1},
		    {label: '不合格', value: 2}
	    ],
	    iqcrTypeList: [
		    {label: '包材抽检',value: 1},
		    {label: '面料抽检',value: 2},
		    {label: '外发半成品抽检',value: 3},
		    {label: '辅料&其余物料抽检',value: 4},
	    ],
	    acctList: []
    }
  },
  created() {
    this.initData()
  },
  watch:{
  },
  filters:{
    // 时间转换
    formatDate(row) {
      return helper.toStringDay(row)
    },
  },
  methods: {
    initData(){
      this.loadingFlag = true
	    this.getAcctData();
	    this.getTableData();
    },
    // 获取表格数据
	  getTableData() {
      const {startTime,endTime} = this.helper.getTime(this.timeArray)
	    this.searchForm.start_time = startTime
	    this.searchForm.end_time = endTime
	    get(iqcrAPI.get_iqcrs, this.searchForm).then(({data}) => {
		    if (data.code === 0) {
			    this.tableData = data.data.list;
			    this.totalPage = data.data.total
			    this.btn = data.data.btn;
			    setTimeout(() => {
				    this.loadingFlag = false
			    }, 500);
		    }
	    })
    },
    // 刷新
    buttonRefresh(){
      this.searchForm = {page_no: 1};
			this.timeArray = []
      this.loadingFlag = true
      this.currentPage = 1
      this.initData()
      this.$refs.pubPagination.currentPage = 1
    },
    // 查询方法
	  getTableDataNow(){
      this.loadingFlag = true
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.initData()
    },
    // 分页查询
    changePageSearch(val){
      this.searchForm.page_no = val
      this.initData()
    },
	  // 获取账套信息
	  getAcctData() {
		  get(acctAPI.getAllAcctsV1,{}).then(({data}) => {
			  if (data.code === 0) {
				  this.acctList = data.data;
			  }
		  })
	  },
	  // 获取检验类别
	  getIqcrTypeName(row) {
		  return this.iqcrTypeList.find(({value}) => value === row.iqcr_type).label
	  },
	  // 物料类型
	  formatLeavType(row){
		  switch (row.mtrl_type) {
			  case 0:
				  return '原面料'
			  case 1:
				  return '辅料'
			  case 2:
				  return '包材'
			  case 3:
				  return '加工面料'
			  case 4:
				  return '裁片'
			  case 5:
				  return '再制品'
		  }
	  },
  }
}
</script>

<style scoped lang="scss">
.vd_button_group{
  width: 100vw;
  position: relative;
}
</style>